import mutationTypes from './mutation-types'

export default {
  [mutationTypes.SET_ADDRESS_VERIFICATIONS]: (state, payload) => {
    state.addressVerifications = payload
  },
  [mutationTypes.SET_ADDRESS_VERIFICATIONS_STATS]: (
    state,
    { verificationsByEntityStats, verificationActionsStats }
  ) => {
    state.verificationsByEntityStats = verificationsByEntityStats
    state.verificationActionsStats = verificationActionsStats
  },
}
