export default {
  verificationsByEntityStatsCount({ verificationsByEntityStats }) {
    return verificationsByEntityStats.reduce(
      (count, stat) => count + stat.count,
      0
    )
  },
  verificationActionsStatsCount({ verificationActionsStats }) {
    return verificationActionsStats.reduce(
      (count, stat) => count + stat.count,
      0
    )
  },
}
