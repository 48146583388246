import mutationTypes from './mutation-types'

export default {
  [mutationTypes.SET_ACCOUNT_INFO]: (
    state,
    { id, emailDomain, name, url } = {}
  ) => {
    state.id = id
    state.emailDomain = emailDomain
    state.name = name
    state.url = url
  },
}
