import actions from './actions'
import mutations from './mutations'

const state = {
  id: undefined,
  emailDomain: undefined,
  name: undefined,
  url: undefined,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
