export default {
  userContextId({ contextMessage }) {
    return contextMessage?.userId
  },
  userIsSalesRep({ contextMessage }) {
    return contextMessage?.permissionName === 'ActivePrime_Sales_Rep'
  },
  userIsAdmin(state, { userIsSalesRep }) {
    return !userIsSalesRep
  },
  permittedActions({ user }) {
    return user ? user.rolePermissions : []
  },
  userCanCreateRoles(state, { permittedActions }) {
    return permittedActions.includes('admin.roles.post')
  },
  userCanUpdateRoles(state, { permittedActions }) {
    return permittedActions.includes('admin.roles.id.post')
  },
  userCanDeleteRoles(state, { permittedActions }) {
    return permittedActions.includes('admin.roles.id.delete')
  },
  userCanUpdateAccounts(state, { permittedActions }) {
    return permittedActions.includes('admin.accounts.id.post')
  },
  userCanInviteNewUsers(state, { permittedActions }) {
    return permittedActions.includes('auth.start_adding_user')
  },
  userCanUpdateUsers(state, { permittedActions }) {
    return permittedActions.includes('admin.users.id.post')
  },
  userCanDeleteUsers(state, { permittedActions }) {
    return permittedActions.includes('admin.users.id.delete')
  },
  userHasRole:
    ({ user }) =>
    (role) => {
      if (!user) {
        return false
      }

      return user.rolePermissions.includes(role)
    },
}
